// extracted by mini-css-extract-plugin
export var ArtistDescription = "Sharp-module--ArtistDescription--q6pYM";
export var ArtistInfos = "Sharp-module--ArtistInfos--BZ7QB";
export var ButtonWrapper = "Sharp-module--ButtonWrapper --VAbN6";
export var CardWrapper = "Sharp-module--CardWrapper--iFkUE";
export var CarrouselWrapper2 = "Sharp-module--CarrouselWrapper2--bQivv";
export var Citations = "Sharp-module--Citations--yBkjI";
export var DescriptionWrapper = "Sharp-module--DescriptionWrapper--R4axR";
export var ImageWrapper = "Sharp-module--ImageWrapper--2xslB";
export var LinkWrapper = "Sharp-module--LinkWrapper--8LDZI";
export var MobileProtrait = "Sharp-module--MobileProtrait--0OzWS";
export var More = "Sharp-module--More--zLKqi";
export var MoreButton = "Sharp-module--MoreButton--Qro+i";
export var NameWrapper = "Sharp-module--NameWrapper--xfcBa";
export var PdpWrapper = "Sharp-module--PdpWrapper--QVSkS";
export var PhotosWrapper = "Sharp-module--PhotosWrapper--RpZZr";
export var ProfilWrapper = "Sharp-module--ProfilWrapper--cwzs5";
export var TitleWrapper = "Sharp-module--TitleWrapper--HmBeD";
export var Wrapper = "Sharp-module--Wrapper--O8A3w";